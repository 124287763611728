import Image from 'next/image';
import { UpperHeadingAndTitle } from '../UpperHeadingAndTitle';

interface IntegrationsProps {
  upperHeading: string;
  title: string;
}

export function Integrations({ upperHeading, title }: IntegrationsProps) {
  return (
    <div className="w-full max-w-7xl flex flex-col items-center justify-center gap-6 px-4 py-12 font-poppins">
      <UpperHeadingAndTitle upperHeading={upperHeading} title={title} />
      <div className="flex items-center justify-center flex-wrap gap-4 max-w-6xl">
        {integrations.map((integrations, index) => (
          <div
            key={index}
            className="flex gap-2 font-medium cursor-default items-center py-2 px-4 text-base rounded-full border border-slate-400 bg-background"
          >
            <Image
              src={integrations.integrationsUrlImages}
              alt={integrations.title}
              width={28}
              height={28}
            />
            <p className="text-lg">{integrations.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

const integrations: {
  title: string;
  integrationsUrlImages: string;
}[] = [
  {
    title: 'Google',
    integrationsUrlImages: '/google-circle-logo.svg',
  },
  {
    title: 'Meta',
    integrationsUrlImages: '/meta-circle-logo.svg',
  },
  {
    title: 'Instagram',
    integrationsUrlImages: '/instagram-circle-logo.svg',
  },
  {
    title: 'Google Analytics',
    integrationsUrlImages: '/google-analytics-circle-logo.svg',
  },
  {
    title: 'Google Search Console',
    integrationsUrlImages: '/google-search-console-circle-logo.svg',
  },
  {
    title: 'Magazord',
    integrationsUrlImages: '/magazord-circle-logo.svg',
  },
  {
    title: 'VTEX',
    integrationsUrlImages: '/vtex-circle-logo.svg',
  },
  {
    title: 'Tray',
    integrationsUrlImages: '/tray-circle-logo.svg',
  },
  {
    title: 'Nuvemshop',
    integrationsUrlImages: '/nuvemshop-circle-logo.svg',
  },
  {
    title: 'Shopify',
    integrationsUrlImages: '/shopify-circle-logo.svg',
  },
  {
    title: 'Vnda',
    integrationsUrlImages: '/vnda-circle-logo.svg',
  },
  {
    title: 'Linx Commerce',
    integrationsUrlImages: '/linx-commerce-circle-logo.svg',
  },
  {
    title: 'Loja Integrada',
    integrationsUrlImages: '/loja-integrada-circle-logo.svg',
  },
  {
    title: 'Irroba',
    integrationsUrlImages: '/irroba-circle-logo.svg',
  },
  {
    title: 'Shoppub',
    integrationsUrlImages: '/shoppub-circle-logo.svg',
  },
  {
    title: 'Uoou Solutions',
    integrationsUrlImages: '/uoou-solutions-circle-logo.svg',
  },
  {
    title: 'Magento',
    integrationsUrlImages: '/magento-circle-logo.svg',
  },
  {
    title: 'Wbuy',
    integrationsUrlImages: '/wbuy-circle-logo.svg',
  },
  {
    title: 'Wake',
    integrationsUrlImages: '/wake-circle-logo.svg',
  },
  {
    title: 'Woo Commerce',
    integrationsUrlImages: '/woo-circle-logo.svg',
  },
  {
    title: 'Uappi',
    integrationsUrlImages: '/uappi-circle-logo.svg',
  },
];
